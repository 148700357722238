import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../components/Layout'
import Button from '../components/shortcodes/Button'
import Hero from '../components/Hero'
import ServiceStyles from '../styles/Service.module.sass'
import CMSPreviewStyles from '../styles/CMS.module.sass'

/**
 * Display a dynamic service template
 *
 * @param {string} title
 * @param {number} price
 */
const ServiceTemplate = ({
  pageIdentifier,
  title,
  h2Title,
  seoTitle,
  seoDescription,
  image,
  price,
  priceNote,
  priceDetails,
  body,
  disableMdx,
  imageAlt,
  redirectTo,
}) => {
  return (
    <Layout
      className={`is-page-${pageIdentifier}`}
      seoTitle={seoTitle}
      seoDescription={seoDescription}
    >
      <section className="section">
        <Hero title={title} showCTA="false" h2Title={h2Title} />
        <div
          className={`container content is-semi-narrow ${ServiceStyles.topContainer}`}
        >
          <div className="columns">
            <div className="column is-half-tablet">
              {image && image.childImageSharp ? (
                <Img
                  fluid={image.childImageSharp.fluid}
                  alt={imageAlt || title}
                  title={imageAlt || title}
                />
              ) : (
                <img
                  src={image}
                  alt={imageAlt || title}
                  title={imageAlt || title}
                />
              )}
            </div>
            <div className={`column is-half-tablet ${ServiceStyles.vitals}`}>
              <strong className="title"><small>From </small> &euro;{price}</strong>
              <p className="has-text-weight-bold">{priceNote}</p>
              <p>{priceDetails}</p>
              <Button text="Get a Quote" />
            </div>
          </div>
        </div>
        <div className="container content is-narrow">
          {/* Markdown fallback for CMS Previews */}
          {disableMdx === true || disableMdx === 'true' ? (
            body
          ) : (
            <MDXRenderer>{body}</MDXRenderer>
          )}
        </div>
      </section>
    </Layout>
  )
}

export { ServiceTemplate }

/**
 * Create Netlify CMS-compatible Preview Component
 */
const ServicePage = ({ data }) => {
  return (
    <ServiceTemplate
      title={data.mdx.frontmatter.title}
      h2Title={data.mdx.frontmatter.h2Title}
      pageIdentifier={data.mdx.fields.pageIdentifier}
      seoTitle={data.mdx.frontmatter.seoTitle}
      seoDescription={data.mdx.frontmatter.seoDescription}
      image={data.mdx.fields.image}
      imageAlt={data.mdx.frontmatter.imageAlt}
      price={data.mdx.frontmatter.price}
      priceNote={data.mdx.frontmatter.priceNote}
      redirectTo={data.mdx.frontmatter.redirectTo}
      priceDetails={data.mdx.frontmatter.priceDetails}
      body={data.mdx.body}
    />
  )
}

export default ServicePage

/**
 * Configure Netlify CMS Editor Preview
 */
const ServicePreview = ({ entry, widgetFor }) => {
  const data = entry.getIn(['data']).toJS()

  if (data) {
    return (
      <ServiceTemplate
        title={data.title}
        pageIdentifier={`${data.slug || 'service'} ${CMSPreviewStyles.cms}`}
        seoTitle=""
        seoDescription=""
        image={data.image}
        price={data.price}
        priceNote={data.priceNote}
        redirectTo={data.redirectTo}
        priceDetails={data.priceDetails}
        body={widgetFor('body')}
        disableMdx="true"
      />
    )
  } else {
    return <div>Loading...</div>
  }
}

export { ServicePreview }

export const post = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        h2Title
        price
        priceNote
        redirectTo
        priceDetails
        seoTitle
        seoDescription
        imageAlt
      }
      fields {
        pageIdentifier
        image {
          childImageSharp {
            fluid(maxWidth: 640, maxHeight: 360) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
